import { lazy } from 'react';
import generateRouter, { RouteConfig } from '../common/GenerateRoutes';
import { getLoginComponent } from './DP2Login';

const DP2Router = ({ history, app }) => {

  const routes: RouteConfig[] = [
    {
      path: '/user/login',
      Component: getLoginComponent(app),
      exact: true,
      hidden: false,
    },
  ];

  return generateRouter({
    routerProps: { app },
    routes,
    overrideDefaultRoutes: true,
    history,
    appComponent: lazy(() => import('./DP2Apps')),
  });
};

export default DP2Router;
