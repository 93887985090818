export const number = {
	currency: {
		common: {
			name: "kr",
			format: "{0} kr",
		},
		iso: {
			name: "SEK",
			format: "{0} SEK",
		},
	},
	separators: {
		thousand: " ",
		decimal: ",",
		group: 3,
	},
};
