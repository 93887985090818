export const forEachKey = <T extends object>(
	obj: T,
	callback: (key: keyof T, value: T[keyof T], index: number) => void,
): void => {
	Object.keys(obj).forEach((key, index) => {
		const value = obj[key as keyof T];
		const _key = key as keyof T;

		callback(_key, value, index);
	});
};
