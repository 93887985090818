import { type ReactNode, type ReactElement, Children, isValidElement } from 'react';

export const filterChildren = (children: ReactNode, filter: (child: ReactElement) => boolean): ReactNode => {
  return Children.map(children, (child) => {
    if (isValidElement(child) && filter(child)) {
      return child;
    }
    return null;
  });
};
