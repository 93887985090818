import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import {
  createStore, applyMiddleware, compose, type Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory as createHistory } from 'history';
import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import { DraggableModalProvider } from 'ant-design-draggable-modal';
import { ApolloProvider } from '@apollo/client';
// import { DPProvider } from '@dp-ui2/components/core/_types/DPProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Favicon from 'react-favicon';
import reducers, { type reducersMaker } from './redux/reducers';
import sagas from './redux/sagas';
import Localization from './components/Layout/Localization';
import 'ant-design-draggable-modal/dist/index.css';
// eslint-disable-next-line import/no-cycle
import client from './config/createApolloClient';
import * as serviceWorker from './serviceWorker';
import 'react-calendar-timeline/lib/Timeline.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import './global.scss';
import Loader from './components/Layout/Loader';
import { googleClientId } from './config/config';
import DPRouter from './apps/apps.router';
import { isP2Module } from './apps/app.util';

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

if (process.env.NODE_ENV === 'development')
{

  middlewares.push(logger);

}
else
{

  // eslint-disable-next-line no-console
  console.log = () =>
  { };

}
const reduxStore: Store<ReturnType<typeof reducersMaker>> = createStore<any, any, any, any>(
  reducers(history), compose(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(sagas);
reduxStore.subscribe(() =>
{

  // @ts-ignore
  const { settings: { locale } } = reduxStore.getState();
  const lang = locale.split('-')[0].toLowerCase();
  const finalLocale = lang === 'sw' ? 'sv' : 'en-gb';
  moment.locale(finalLocale);

});

const App = () => (
  <DraggableModalProvider>
    <Favicon url={isP2Module ? `${window.location.origin}/p2Favicon.svg` : `${window.location.origin}/favicon.svg`} />
    <GoogleOAuthProvider clientId={googleClientId}>
      <ApolloProvider client={client}>
        <Provider store={reduxStore}>
          <Localization>
            {/* <DPProvider> */}
              <Suspense fallback={<Loader spinning fullScreen />}>
                <DPRouter history={history} />
              </Suspense>
            {/* </DPProvider> */}
          </Localization>
        </Provider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  </DraggableModalProvider>
)
const container = document.getElementById('root');
if (container)
{

  const root = createRoot(container);
  root.render(<App />);

}
else
{

  console.error('No root element found');

}

// This is tender Method

// @ts-ignore
global.renderCount = 0;
// @ts-ignore
global.renderGCount = 0;
serviceWorker.unregister();

export { reduxStore as store, history };
