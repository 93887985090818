export const replaceVal = (obj: any, matchedVal: any, newValue: unknown): void => {
	if (!obj || typeof obj !== "object") return;

	if (Array.isArray(obj)) {
		for (let i = 0; i < obj.length; i++) {
			if (equal(obj[i], matchedVal)) {
				obj[i] = newValue;
			} else if (obj[i] && typeof obj[i] === "object") {
				replaceVal(obj[i], matchedVal, newValue);
			}
		}
		return;
	}

	for (const key in obj) {
		if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;

		if (equal(obj[key], matchedVal)) {
			obj[key] = newValue;
		} else if (obj[key] && typeof obj[key] === "object") {
			replaceVal(obj[key], matchedVal, newValue);
		}
	}
};

const equal = (a: any, b: any): boolean => {
	if (a === b) return true;
	if (!a || !b || typeof a !== typeof b) return false;
	if (typeof a !== "object") return a === b;

	if (Array.isArray(a)) {
		return Array.isArray(b) && a.length === b.length && a.every((item, i) => equal(item, b[i]));
	}

	const keysA = Object.keys(a);
	const keysB = Object.keys(b);

	return keysA.length === keysB.length && keysA.every((k) => keysB.includes(k) && equal(a[k], b[k]));
};
