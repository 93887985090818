export const crawlObjectTree = (
	obj: any,
	callback: (key: string, value: any, path: string[]) => void,
	path: string[] = [],
): void => {
	Object.entries(obj).forEach(([key, value]) => {
		const currentPath = [...path, key];
		callback(key, value, currentPath);

		if (value && typeof value === "object" && !Array.isArray(value)) {
			crawlObjectTree(value, callback, currentPath);
		}
	});
};
