import { Suspense, lazy } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import Error404Page from '@src/views/404'
import Loader from '../../components/Layout/Loader'
import DPIndexLayout from '../common/DPIndexLayout/DPIndexLayout'
import PSLogo from '../../assets/logo/ps-mypages-logo.png';
import 'antd/dist/antd.css';


const PawnShopCustomerRouter = ({
  history,
}) =>
{


  const routes = [
    {
      path: '/user/login',
      Component: lazy(() => import('@src/views/LoginView/BankId.view')),
      exact: true,
      hidden: false,
      props: { logo: PSLogo },
    },
    {
      path: '/ps',
      Component: lazy(() => import('./components/Shop/view/PS.view')),
      exact: true,
      hidden: false,
    },


  ]
  return (
    // @ts-ignore
    <ConnectedRouter history={history}>
      <DPIndexLayout defaultPath="/ps">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/ps" />} />
          {routes.map(({
            path, Component, exact, hidden, props = {},
          }) => !hidden && (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={render_props => (
              <Suspense fallback={<Loader fullScreen={false} />}>
                <Component {...render_props} {...props}/>
              </Suspense>
            )}
          />
          ))}
          <Route component={Error404Page} />
        </Switch>
      </DPIndexLayout>
    </ConnectedRouter>
  )

}

export default PawnShopCustomerRouter
