import { getNodeType } from "@dp-core/utils/DPReactUtil/functions/getNodeType";
import type React from "react";

export const excludeNodesByType = (children: React.ReactNode[], types: string | string[]) => {
  const typesArray = Array.isArray(types) ? types : [types];

  const res = children
    .filter((child: any) => !typesArray.includes(getNodeType(child)))
    .map((child: any) => getNodeType(child))
    .filter(Boolean);

  return res;
};
