import { type ReactNode, isValidElement } from 'react';

export const getNodeType = (node: ReactNode): string => {
  if (isValidElement(node)) {
    const nodeType = node.type;
    if (typeof nodeType === "function") {
      return nodeType.name || (nodeType as Function)()?.type.name || "Anonymous";
    }
    if (typeof nodeType === "string") {
      return nodeType;
    }
  }

  if (typeof node === "string") {
    return `text:${node}`;
  }

  return "Unknown";
};
