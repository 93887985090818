import { debugError } from "@dp-core/error/debugError";
import { isNullOrUndefined } from "@dp-core/utils/ObjEx/isNullOrUndefined";

export const assertPropVal = <T>(obj: unknown, propName: string, defaultValue: T | (() => T)): T | undefined => {
	if (typeof obj === "object" && obj !== null) {
		const record = obj as Record<string, unknown>;

		if (isNullOrUndefined(record[propName])) {
			const _default = typeof defaultValue === "function" ? (defaultValue as () => T)() : defaultValue;
			record[propName] = _default;
			return _default;
		}

		return record[propName] as T;
	}

	debugError('"obj" is not an object');
	return;
};
