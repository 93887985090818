export const getFunctionParameters = (functionString: string): string[] => {
	const _functionString = functionString.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, "");
	const match = _functionString.match(/\(([^)]*)\)/);
	if (!match) return [];

	const res = match[1]
		.split(",")
		.map((param) => param.trim())
		.filter((param) => param.length > 0)
		.map((param) => {
			const destructureMatch = param.match(/^(?:\{[^}]*\}|\[[^\]]*\])\s*(?::[^=]+)?(?=\s*(?:=|$))/);

			if (destructureMatch) {
				return destructureMatch[0];
			}

			const res = param.split(":")[0].split("=")[0].trim();
			return res;
		});

	return res;
};
