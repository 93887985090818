export const allFieldsAreUndefined = (obj: Record<string, unknown>): boolean => {
	if (!obj) {
		return true;
	}

	for (const key in obj) {
		if (obj[key] !== undefined) {
			return false;
		}
	}
	return true;
};
