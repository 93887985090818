import type { ReactNode } from "react";

export const isLocalhost = (): boolean => global.location?.hostname === "localhost" || global.location?.hostname === "127.0.0.1";

export const isPublic = () => global.location?.href.includes("/p/");

export const isDebug = (): boolean => {
  const isLocal = isLocalhost();
  const isDebugLocal = isLocal && !global.location?.search.includes("debug=false");
  const isDebugRemote = !isLocal && global.location?.search.includes("debug");
  const isDebug = isDebugLocal || isDebugRemote;
  return isDebug;
};

export interface DPProviderProps {
  children: ReactNode;
}

export const DPProvider = (props: DPProviderProps) => <>{props?.children}</>;
