import { formats, months, weekdays } from "@dp-core/lang/translations/sv-SE/date";
import { number } from "@dp-core/lang/translations/sv-SE/number";
import { phrases } from "@dp-core/lang/translations/sv-SE/phrases";
import { DPLocaleConfig } from "@dp-core/lang/types";

export const sv_SE: DPLocaleConfig = {
	date: {
		months,
		weekdays,
		formats,
	},
	number,
	phrases,
};
