import type React from "react";
import { Suspense, lazy } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import Error404Page from "@src/views/404";
import DPIndexLayout from "../DPIndexLayout/DPIndexLayout";
import Loader from "@src/components/Layout/Loader";
import "antd4/dist/antd.css";

/**
 * RouteConfig interface defines the structure for a single route configuration.
 * @interface RouteConfig
 */
export interface RouteConfig {
  /**
   * The URL path for the route (e.g., "/user/login")
   * Must start with a forward slash
   */
  path: string;

  /**
   * The lazy-loaded React component to render for this route
   * Should be wrapped in React.lazy() for code splitting
   */
  Component: React.LazyExoticComponent<React.ComponentType<any>>;

  /**
   * If true, the route will only match if the path matches exactly
   * Example: exact: true with path "/users" will not match "/users/123"
   * @default false
   */
  exact?: boolean;

  /**
   * If true, the route will not be rendered in the router
   * Useful for temporarily disabling routes without removing them
   * @default false
   */
  hidden?: boolean;

  /**
   * Additional props to pass to the rendered component
   * Can include any valid props the component accepts
   * Example: { logo: LogoComponent, theme: "dark" }
   */
  props?: Record<string, any>;
}

/**
 * RouterProps interface defines the configuration options for the router component
 * @interface RouterProps
 */
interface RouterProps {
  /**
   * Browser history object for handling navigation
   * Required for the ConnectedRouter component
   */
  history: any;

  /**
   * Optional main application component that will be rendered at the default path
   * Should be wrapped in React.lazy() for code splitting
   */
  appComponent?: React.LazyExoticComponent<React.ComponentType<any>>;

  /**
   * Array of route configurations
   * Each route defines a path and its corresponding component
   * @default []
   */
  routes?: RouteConfig[];

  /**
   * The default path where the main application component will be rendered
   * @default "/"
   */
  defaultPath?: string;

  /**
   * If true, only the provided routes will be used
   * If false, provided routes will be combined with default authentication routes
   * @default false
   */
  overrideDefaultRoutes?: boolean;

  /**
   * URL or component for the application logo
   * Will be passed to components that need to display the logo
   */
  logo?: string;


  /**
   * Additional props to pass to the router component
   * Can include any valid props the component accepts
   * Example: { theme: "dark" }
   * 
   * @default {}
   * @type {any}
   * @memberof RouterProps
   * @example
   * <Router history={history} appComponent={App} routes={routes} defaultPath="/home" overrideDefaultRoutes={true} logo={logo} routerProps={{ theme: "dark" }} />
   *
   */
  routerProps?: any;
}

/**
 * Generates a router component with the specified configuration
 * @param props - Router configuration props
 * @param props.history - Browser history object for navigation
 * @param props.appComponent - Main application component (optional)
 * @param props.routes - Array of route configurations (optional)
 * @param props.defaultPath - Default path for the router (defaults to "/")
 * @param props.overrideDefaultRoutes - Whether to override default routes (optional)
 * @param props.logo - Logo URL or component (optional)
 * @returns {JSX.Element} Configured router component with all specified routes
 */
const generateRouter: React.FC<RouterProps> = ({
  history,
  appComponent,
  routes = [],
  defaultPath = "/",
  overrideDefaultRoutes,
  logo,
  routerProps = {},
}) => {
  const defaultRoutes: RouteConfig[] = [
    {
      path: "/user/login",
      Component: lazy(() => import("@src/views/LoginView")),
      exact: true,
      hidden: false,
      props: { logo },
    },
    {
      path: "/user/login/bankid",
      Component: lazy(() => import("@src/views/LoginView")),
      exact: true,
    },
    {
      path: "/user/password/reset",
      Component: lazy(() => import("@src/views/PasswordResetView")),
      exact: true,
      hidden: false,
    },
    {
      path: "/user/forgot",
      Component: lazy(() => import("@src/views/ForgotView")),
      exact: true,
      hidden: false,
    },
  ];

  // If routes are provided, they override defaultRoutes
  const finalRoutes = overrideDefaultRoutes ? routes : [...defaultRoutes, ...routes];

  // Always add the app-specific component to the routes
  if (appComponent) {
    finalRoutes.unshift({
      path: defaultPath,
      Component: appComponent,
      exact: true,
      hidden: false,
    });
  }

  return (
    // @ts-ignore
    <ConnectedRouter history={history}>
      <DPIndexLayout defaultPath={defaultPath}>
        <Switch>
          {finalRoutes.map(({ path, Component, exact, hidden, props = {} }) =>
            !hidden ? (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={(renderProps) => (
                  <Suspense fallback={<Loader fullScreen={false} />}>
                    <Component {...renderProps} {...props} {...routerProps} />
                  </Suspense>
                )}
              />
            ) : null,
          )}
          <Route component={Error404Page} />
        </Switch>
      </DPIndexLayout>
    </ConnectedRouter>
  );
};

export default generateRouter;
