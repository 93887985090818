import { ArrEx } from "@dp-core/utils/array/ArrEx";
import { ReactEx } from "@dp-core/utils/DPReactUtil/_ReactEx";
import { isValidElement } from "react";

// truncate string
// ---------------------------------------------------------------------------------------------------------
const truncateString = (str: unknown): string => {
	if (str == null) return "";

	const maxLength = 200;
	const stringValue = String(str).replace(/\t/g, "  ");

	return stringValue.length <= maxLength ? stringValue : `${stringValue.slice(0, maxLength - 3)}...`;
};

// stringify message
// ---------------------------------------------------------------------------------------------------------
export const stringifyMessage = (msg: unknown, maxWidth = 80): string => {
	// null or undefined
	// -----------------
	if (msg == null || typeof msg === "undefined") {
		return String(msg);
	}

	// is
	// --
	const isFunction = typeof msg === "function";
	const isMap = msg instanceof Map;
	const isArray = ArrEx.isArrayLike(msg);
	const isObject = typeof msg === "object" && msg !== null;
	const isString = typeof msg === "string";
	const isSymbol = typeof msg === "symbol";
	const isBoolean = typeof msg === "boolean";
	const isNumber = typeof msg === "number";
	const isReactElement = isValidElement(msg);

	// react element
	// -------------
	if (isReactElement) {
		return `React component: ${ReactEx.getNodeType(msg.toString())}`;
	}

	// function
	// --------
	if (isFunction) {
		return stringifyMessage(msg(), maxWidth);
	}

	// map
	// ----
	else if (isMap) {
		return stringifyMap(msg, maxWidth);
	}

	// array
	// ------
	else if (isArray) {
		const arr = Array.from(msg as any);
		const stringifiedItems = arr.map((item) => stringifyMessage(item, maxWidth));
		return formatArray(stringifiedItems, maxWidth);
	}

	// object
	// --------
	else if (isObject) {
		return formatJson(msg as Record<string, unknown>, maxWidth);
	}

	// string
	// -------
	else if (isString) {
		const _msg = msg.replace(/\"\'|\'\"/g, "");
		return truncateString(_msg);
	}

	// boolean
	// --------
	else if (isBoolean) {
		return msg ? "true" : "false";
	}

	// react element
	// -------------
	else if (isSymbol) {
		return `React component: ${ReactEx.getNodeType(msg.toString())}`;
	}

	// number
	// --------
	else if (isNumber) {
		return String(msg);
	}

	// unhandled
	// ---------
	throw new Error("Unhandled stringifyMessage type");
};

// format array
// ---------------------------------------------------------------------------------------------------------
const formatArray = (items: string[], maxWidth: number): string => {
	// For empty arrays
	if (items.length === 0) {
		return "[]";
	}

	// Try single line format first
	const singleLine = items.map((item, i) => `${i + 1}. ${item}`).join("\n");

	if (singleLine.length <= maxWidth) {
		return singleLine;
	}

	// Multi-line format with proper indentation
	const indent = "  ";
	const itemsFormatted = items.map((item) => `${indent}${item}`).join(",\n");

	return `[\n${itemsFormatted}\n]`;
};

// format json
// ---------------------------------------------------------------------------------------------------------
const formatJson = (obj: Record<string, unknown>, maxWidth = 70, depth = 0, seen = new WeakSet()): string => {
	if (depth > 5) return "[Max depth exceeded]";
	if (obj == null || seen.has(obj)) return "[Circular]";
	seen.add(obj);

	const indent = "  ".repeat(depth);
	const nextIndent = "  ".repeat(depth + 1);

	const formatValue = (value: unknown, keyLength: number): string => {
		if (value === undefined) return "undefined";
		if (value === null) return "null";

		const remainingWidth = maxWidth - nextIndent.length - keyLength - 2;

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				if (value.length === 0) return "[]";
				const arrayItems = value.map((v) => `${nextIndent}  ${formatValue(v, 0)}`).join(",\n");
				return `[\n${arrayItems}\n${nextIndent}]`;
			}
			if (Object.keys(value).length === 0) return "{}";
			return formatJson(value as Record<string, unknown>, maxWidth, depth + 1, seen);
		}

		const stringValue = String(value);
		if (stringValue.length <= remainingWidth) {
			return JSON.stringify(value);
		}
		return `\n${nextIndent}  ${truncateString(stringValue)}`;
	};

	const entries = Object.entries(obj);
	if (entries.length === 0) return "{}";

	const formattedEntries = entries.map(([key, value]) => {
		const formattedKey = key.replace(/"/g, "");
		const formattedValue = formatValue(value, formattedKey.length);
		return `${nextIndent}${formattedKey}: ${formattedValue}`;
	});

	const content = formattedEntries.join(",\n");
	return `{\n${content}\n${indent}}`;
};

// stringify map
// ---------------------------------------------------------------------------------------------------------
const stringifyMap = (map: Map<unknown, unknown>, maxWidth: number): string => {
	const entries = Array.from(map.entries()).map(([key, value]) => {
		const stringKey = stringifyMessage(key, maxWidth / 2);
		const stringValue = stringifyMessage(value, maxWidth / 2);
		return `${stringKey}: ${stringValue}`;
	});
	return `Map {\n  ${entries.join(",\n  ")}\n}`;
};

// create frame
// ---------------------------------------------------------------------------------------------------------
export const createFrame = (
	content: string[],
	{ char, maxWidth = 70 }: { color: string; char: string; maxWidth?: number },
): string => {
	const wrappedContent = content.flatMap((line) =>
		line.length > maxWidth ? line.match(new RegExp(`.{1,${maxWidth}}`, "g")) || [] : [line],
	);

	const contentWidth = Math.min(maxWidth, Math.max(...wrappedContent.map((line) => line.length)));
	const horizontalBorder = char.repeat(contentWidth);

	const framedLines = [
		`${horizontalBorder}`,
		...wrappedContent.map((line) => `${line.padEnd(contentWidth)}`),
		`${horizontalBorder}`,
	];

	return framedLines.join("\n");
};

export const COLORS = {
	reset: "\x1b[0m",
	lightBlue: "\x1b[94m",
	darkPink: "\x1b[38;2;255;20;147m",
} as const;
