import { formats, months, weekdays } from "@dp-core/lang/translations/en-US/date";
import { number } from "@dp-core/lang/translations/en-US/number";
import { phrases } from "@dp-core/lang/translations/en-US/phrases";
import { DPLocaleConfig } from "@dp-core/lang/types";

export const en_US: DPLocaleConfig = {
	date: {
		formats,
		months,
		weekdays,
	},
	number,
	phrases,
};
