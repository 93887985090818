import { isEmptyObject } from "@dp-core/utils/ObjEx/isEmptyObject";

export const firstNonEmpty = <T>(...args: unknown[]): T => {
	let lastNotUndefined!: T;

	const arg = args.find((arg) => {
		if (Array.isArray(arg) && arg.length === 0) {
			lastNotUndefined = arg as T;
			return false;
		}

		if (isEmptyObject(arg as object)) {
			if (arg !== undefined) {
				lastNotUndefined = arg as T;
			}
			return false;
		}

		return true;
	});

	if (!arg && args) {
		if (Array.isArray(lastNotUndefined)) return [] as T;
		if (lastNotUndefined === false || lastNotUndefined === true) return lastNotUndefined;
		if (isEmptyObject(lastNotUndefined as object)) return {} as T;
	}

	return arg as T;
};
