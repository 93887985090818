import antdData from "antd4/lib/locale-provider/sv_SE";
import messages from "./old_translations/sv-sw.json";
import DPPartsSWLocale from "@dp-parts/locales/translations/sw-SW.json";
import DPUISWLocale from "@dp-ui/locales/translations/sw-SW.json";
import { phrases as sv_SE } from "@dp-core/lang/translations/sv-SE/phrases";

export default {
	locale: "sv-SE",
	antdData,
	messages: {
		...sv_SE,
		...messages,
		...DPPartsSWLocale,
		...DPUISWLocale,
		openStatsView: "Öppna statistikvy",
		recentSelections: "Senaste val",
		allFacilities: "Alla anläggningar",
		receiverEmail: "Mottagarens e-post",
		downloadTemplate: "Ladda ner mall",
		deleteMeter: "Radera Mätare",
		deleteMeterConfirmation: "Är du säker på att du vill radera denna mätare?",
		deleteMeterWithReadings: "Radera Mätare och alla Mätvärden",
	},
};
