import { cloneChildren } from "@dp-core/utils/DPReactUtil/functions/cloneChildren";
import { cloneChildrenRecursively } from "@dp-core/utils/DPReactUtil/functions/cloneChildrenRecursive";
import { crawlElementTree } from "@dp-core/utils/DPReactUtil/functions/crawlElementTree";
import { eachNodeIsType } from "@dp-core/utils/DPReactUtil/functions/eachNodeIsType";
import { excludeNodesByType } from "@dp-core/utils/DPReactUtil/functions/excludeNodesByType";
import { filterByType } from "@dp-core/utils/DPReactUtil/functions/filterByType";
import { filterChildren } from "@dp-core/utils/DPReactUtil/functions/filterChildren";
import { getNodeType } from "@dp-core/utils/DPReactUtil/functions/getNodeType";
import { isComponentType } from "@dp-core/utils/DPReactUtil/functions/isComponentType";

export class ReactEx {
  static cloneChildren = cloneChildren;
  static filterChildren = filterChildren;
  static filterByType = filterByType;
  static crawlElementTree = crawlElementTree;
  static getNodeType = getNodeType;
  static cloneChildrenRecursively = cloneChildrenRecursively;
  static isComponentType = isComponentType;
  static excludeNodesByType = excludeNodesByType;
  static eachNodeIsType = eachNodeIsType;
}
