export const formats = {
	"year-month": "{year}-{month}",
	"year-month-day": "{year}-{month}-{day}",
	"year-month-day-hour-minute": "{year}-{month}-{day} {hour}:{minute}",
	"year-month-day-hour-minute-second": "{year}-{month}-{day} {hour}:{minute}:{second}",
};

export const months = {
	name: {
		1: "januari",
		2: "februari",
		3: "mars",
		4: "april",
		5: "maj",
		6: "juni",
		7: "juli",
		8: "augusti",
		9: "september",
		10: "oktober",
		11: "november",
		12: "december",
	},

	short: {
		1: "jan",
		2: "feb",
		3: "mar",
		4: "apr",
		5: "maj",
		6: "jun",
		7: "jul",
		8: "aug",
		9: "sep",
		10: "okt",
		11: "nov",
		12: "dec",
	},
};

export const weekdays = {
	name: {
		1: "måndag",
		2: "tisdag",
		3: "onsdag",
		4: "torsdag",
		5: "fredag",
		6: "lördag",
		7: "söndag",
	},

	short: {
		1: "mån",
		2: "tis",
		3: "ons",
		4: "tor",
		5: "fre",
		6: "lör",
		7: "sön",
	},
};
