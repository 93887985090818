import {
  all, takeEvery, put, call,
} from 'redux-saga/effects'
import { currentAccount, logout } from '../../services/user'
import actions from './actions'

export function* LOAD_CURRENT_ACCOUNT(user)
{

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let response;
  if (!user)
  {

    response = yield call(currentAccount)

  }
  else
  {

    response = JSON.parse(global.localStorage.getItem('userInfo'))

  }
  if (response)
  {

    const {
      _id,
      role,
      email,
      token,
      firstName,
      lastName,
      photoURL: avatar,
      accounts,
      selectedFilters,
      beta,
      selectedAccount,
      profileImage,
      starProjects,
      settings,
    } = response;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        _id,
        accounts,
        token,
        firstName,
        lastName,
        // name: 'Administrator',
        email,
        avatar,
        role,
        authorized: true,
        selectedFilters,
        beta,
        selectedAccount,
        profileImage,
        starProjects,
        settings,
      },
    })

  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })

}

export function* LOGOUT()
{

  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      _id: '',
      lastName: '',
      firstName: '',
      accounts: [],
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      profileImage: '',
      loading: false,
    },
  })

}

export default function* rootSaga()
{

  yield all([
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])

}
