import { debugError } from "@dp-core/error/debugError";
import { DPLang } from "@dp-core/lang/DPLang";
import type { DPDateFormats } from "@dp-core/lang/types";

export const formatDate = (date: Date | string | number, format: DPDateFormats = "year-month-day"): string => {
	const _date = date instanceof Date ? date : new Date(date);

	if (Number.isNaN(_date.getTime())) {
		return "";
	}

	const pad = (n: number): string => n.toString().padStart(2, "0");
	const year = _date.getFullYear();
	const month = _date.getMonth() + 1;
	const weekday = _date.getDay();
	const day = _date.getDate();
	const hour = _date.getHours();
	const minute = _date.getMinutes();
	const second = _date.getSeconds();
	const ms = _date.getMilliseconds();

	// standard formats
	// ----------------
	switch (format) {
		case "year":
			return year.toString();
		case "month":
			return pad(month);
		case "day":
			return pad(day);
		case "hour":
			return pad(hour);
		case "minute":
			return pad(minute);
		case "second":
			return pad(second);
		case "millisecond":
			return ms.toString();
		case "dayofweek":
			return weekday.toString();
	}

	// lang specific formats
	// ---------------------
	const conf = DPLang.getCurrentLocaleConfig();

	switch (format) {
		case "month-name":
			return conf.date.months.name[month];
		case "month-name-short":
			return conf.date.months.short[month];
		case "weekday-name":
			return conf.date.weekdays.name[weekday];
		case "weekday-name-short":
			return conf.date.weekdays.short[weekday];
		case "year-month":
		case "year-month-day":
		case "year-month-day-hour-minute":
		case "year-month-day-hour-minute-second": {
			const _format = conf.date.formats[format];
			const res = _format
				.replace("{year}", year.toString())
				.replace("{month}", pad(month))
				.replace("{day}", pad(day))
				.replace("{hour}", pad(hour))
				.replace("{minute}", pad(minute))
				.replace("{second}", pad(second))
				.replace("{month-name}", conf.date.months.name[month])
				.replace("{month-name-short}", conf.date.months.short[month])
				.replace("{weekday}", conf.date.weekdays.name[weekday])
				.replace("{weekday}", conf.date.weekdays.name[getISOWeek(_date)])
				.replace("{ms}", ms.toString());

			return res;
		}
	}

	// unhandled format
	// ----------------
	debugError(`Unhandled format: ${format}`);
	return "";
};

// get iso week
// ------------------------------------------------------------------------------------------------------
const getISOWeek = (date: Date) => {
	let _date = date;
	_date = new Date(date);
	_date.setHours(0, 0, 0, 0);
	_date.setDate(date.getDate() + 3 - ((_date.getDay() + 6) % 7));
	const week1 = new Date(date.getFullYear(), 0, 4);
	return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};
