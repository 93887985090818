export const number = {
	currency: {
		common: {
			name: "$",
			format: "${0}",
		},
		iso: {
			name: "USD",
			format: "{0} USD",
		},
	},
	separators: {
		thousand: ",",
		decimal: ".",
		group: 3,
	},
};
