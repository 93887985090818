import { MapEx } from "../MapEx";
import { ObjEx } from "../ObjEx/_ObjEx";

export class ArrEx {
	// is array of strings
	// ------------------------------------------------------------------------------------
	static isArrayOfStrings(value: unknown): value is string[] {
		return Array.isArray(value) && value.every((element) => typeof element === "string");
	}

	// remove null and undefined from array
	// ------------------------------------------------------------------------------------
	static removeEmpty<T>(array: (T | null | undefined)[]): T[] {
		return array.filter(
			(element): element is T => element != null && element !== undefined && !ObjEx.isEmptyObject(element),
		);
	}

	// remove duplicates from array
	// ------------------------------------------------------------------------------------
	static removeDuplicates<T>(array: (T | null | undefined)[]): T[] {
		return Array.from(new Set(array.filter((item): item is T => item != null)));
	}

	// remove item from array
	// ------------------------------------------------------------------------------------
	static arrayRemove(array: any[], item: any): any[] {
		return array.filter((element) => element !== item);
	}

	// move item to the first position
	// ------------------------------------------------------------------------------------
	static moveItemFirst<T>(arr: T[], item: T): T[] {
		const index = arr.indexOf(item);
		if (index > -1) {
			return [item, ...arr.slice(0, index), ...arr.slice(index + 1)];
		}
		return [...arr];
	}

	// move item to last
	// ------------------------------------------------------------------------------------
	static moveItemLast<T>(arr: T[], item: T): T[] {
		const index = arr.indexOf(item);
		if (index > -1) {
			return [...arr.slice(0, index), ...arr.slice(index + 1), item];
		}
		return [...arr];
	}

	// find the first item that matches the filter
	// ------------------------------------------------------------------------------------
	static first<T>(arr: T[] | Map<any, T>, filter?: (item: T) => boolean): T | undefined {
		// array
		// -----
		if (Array.isArray(arr)) {
			return filter ? arr.find(filter) : arr[0];
		}

		// map
		// ---
		else if (MapEx.isMap(arr)) {
			return MapEx.first(arr, filter);
		}

		// none of the above
		// -----------------
		else {
			return arr;
		}
	}

	static last<T>(arr: T[] | Map<any, T>, filter?: (item: T) => boolean): T | undefined {
		if (Array.isArray(arr)) {
			const reversed = arr.slice().reverse();
			return filter ? reversed.find(filter) : arr[arr.length - 1];
		}

		return MapEx.last(arr, filter);
	}

	// find the nearest item from the given index
	// ------------------------------------------------------------------------------------
	static findNearestItemFromIndex(
		items: unknown[],
		filterFn: (item: unknown) => boolean,
		index: number,
		direction: "before" | "after",
	): unknown | null {
		if (direction === "before") {
			for (let i = index - 1; i >= 0; i--) {
				if (filterFn(items[i])) {
					return items[i];
				}
			}
		} else {
			for (let i = index + 1; i < items.length; i++) {
				if (filterFn(items[i])) {
					return items[i];
				}
			}
		}
		return null;
	}

	// key array to object
	// ------------------------------------------------------------------------------------
	static keyArrayToObject<T extends string, D extends { key: string }>(
		vars: D[],
		...keysToExtract: T[]
	): { [K in T]?: D } {
		return vars.reduce(
			(acc, v) => {
				if (keysToExtract.includes(v.key as T)) {
					acc[v.key as T] = v;
				}
				return acc;
			},
			{} as { [K in T]?: D },
		);
	}

	// update array item
	// ------------------------------------------------------------------------------------
	static updateArrayItem(
		arr: any[],
		updatedItem: any,
		comparePropertyKey: string = "_id",
		where: "prepend" | "append" = "append",
	): any[] {
		const index = arr.findIndex((item) => item[comparePropertyKey] === updatedItem[comparePropertyKey]);

		if (index !== -1) {
			const res = [...arr.slice(0, index), { ...arr[index], ...updatedItem }, ...arr.slice(index + 1)];

			return res;
		}

		const res = where === "append" ? [...arr, updatedItem] : [updatedItem, ...arr];
		return res;
	}

	// replace array item
	// ------------------------------------------------------------------------------------
	static replaceArrayItem<T extends Record<string, any>>(
		arr: T[],
		item: T,
		comparePropertyKey: keyof T = "_id" as keyof T,
	): T[] {
		const index = arr.findIndex((existingItem) => existingItem[comparePropertyKey] === item[comparePropertyKey]);

		if (index !== -1) {
			return [...arr.slice(0, index), item, ...arr.slice(index + 1)];
		}

		return arr; // Return original array if item not found
	}

	// push or replace array item
	// ------------------------------------------------------------------------------------
	static pushOrReplaceArrayItem<T extends Record<string, any>>(
		arr: T[],
		item: T,
		comparePropKeys: keyof T | (keyof T)[] = "_id" as keyof T,
	): T[] {
		const keys = Array.isArray(comparePropKeys) ? comparePropKeys : [comparePropKeys];

		const index = arr.findIndex((existingItem) => keys.every((key) => existingItem[key] === item[key]));

		if (index !== -1) {
			// Item found, replace it
			return [...arr.slice(0, index), item, ...arr.slice(index + 1)];
		}

		// Item not found, push it
		return [...arr, item];
	}

	// delete array item
	// ------------------------------------------------------------------------------------
	static deleteArrayItem<T extends Record<string, any>>(
		arr: T[],
		item: T,
		comparePropertyKey: keyof T = "_id" as keyof T,
	): T[] {
		const res = arr.filter((existingItem) => existingItem[comparePropertyKey] !== item[comparePropertyKey]);

		return res;
	}

	// arrays are equal
	// ------------------------------------------------------------------------------------
	static arraysEqual<T>(a?: T[], b?: T[]): boolean {
		if (a === b) {
			return true;
		}
		if (a == null || b == null) {
			return false;
		}
		if (a.length !== b.length) {
			return false;
		}

		for (let i = 0; i < a.length; i++) {
			if (a[i] !== b[i]) {
				return false;
			}
		}
		return true;
	}

	// foreach that ensures the input is an array
	// ------------------------------------------------------------------------------------
	static forEach<T>(value: T | T[] | null | undefined, callback: (value: T) => void): void {
		ArrEx.ensureArray(value).forEach(callback);
	}

	// is array-like
	// ------------------------------------------------------------------------------------
	static isArrayLike(value: unknown): boolean {
		if (!value || typeof value !== "object") {
			return false;
		}

		const keys = Object.keys(value as {});
		const _keys = ObjEx.cloneObjectProps(keys);
		const allKeysAreNumbers = _keys.every((key) => /^[\dk]+$/.test(key));
		const isNotEmpty = keys.length > 0;
		const isArray = Array.isArray(value);

		return isNotEmpty && (isArray || allKeysAreNumbers);
	}

	// convert array-like to array
	// ------------------------------------------------------------------------------------
	static ensureArray<T = any>(value: any | any[] | ArrayLike<any> | null | undefined): T[] {
		if (value == null) {
			return [] as T[];
		}

		if (Array.isArray(value)) {
			return value as T[];
		}

		if (ArrEx.isArrayLike(value)) {
			return Object.values(value) as T[];
		}

		if (typeof value === "object" && "length" in value) {
			return Array.from(value) as T[];
		}

		return [value] as T[];
	}

	// toggle item
	// ------------------------------------------------------------------------------------
	static toggleItem<T>(array: T[], item: T, compareProp: keyof T): T[] {
		const index = array.findIndex((el) => el[compareProp] === item[compareProp]);

		// remove if exists
		// ----------------
		if (index !== -1) {
			return [...array.slice(0, index), ...array.slice(index + 1)];
		}

		// add if it doesn't
		// -----------------
		else {
			return [...array, item];
		}
	}

	// exists
	// ------------------------------------------------------------------------------------
	static exists<T>(array: T[], item: T, compareProp: keyof T): boolean {
		const exists = array?.some((el) => el[compareProp] === item[compareProp]);
		return exists;
	}

	// next
	// ------------------------------------------------------------------------------------
	static next<T>(array: T[], current: T): T | undefined {
		const index = array.indexOf(current);
		return array[index + 1];
	}
}
