export const arePropsEqual = (objA: object | undefined, objB: object | undefined): boolean => {
	if (!objA || !objB) {
		return objA === objB;
	}

	for (const key in objA) {
		const valueA = (objA as any)[key];
		const valueB = (objB as any)[key];
		if (!!valueA && !!valueB) {
			if (valueA !== valueB) {
				return false;
			}
		} else {
			return false;
		}
	}
	return true;
};
