import { DPLang } from "@dp-core/lang/DPLang";
import type { DPCurrencyFormats, DPNumberFormats } from "@dp-core/lang/types";

// format number
// -------------------------------------------------------------------------------------------
export const formatNumber = (val: number | string, format: DPNumberFormats, decimals: number = 2): string => {
	const num = typeof val === "string" ? Number.parseFloat(val) : val;

	if (Number.isNaN(num)) {
		return "";
	}

	let result: string;
	switch (format) {
		case "any-number":
			result = num.toString();
			break;
		case "integer":
			result = Math.round(num).toString();
			break;
		case "decimal":
			result = num.toFixed(2);
			break;
		case "currency-common":
			return formatCurrency(num, decimals, format);
		case "currency-iso":
			return formatCurrency(num, decimals, format);
		case "percent":
			result = `${(num * 100).toFixed(2)}%`;
			break;
		default:
			return "";
	}

	// Apply separators to all number formats except percent which already has formatting
	if (format !== "percent") {
		result = formatNumberWithSeparators(Number(result));
	}

	return result;
};

// format currency
// -------------------------------------------------------------------------------------------
export const formatCurrency = (val: number, decimals: number, format: DPCurrencyFormats): string => {
	const locale = DPLang.getCurrentLocaleConfig();
	const currency = locale.number.currency;
	const _key = format?.split("-")?.[1] || "common";
	const str = currency[_key].format;
	const formattedNumber = formatNumberWithSeparators(Number(val.toFixed(decimals)));
	return str.replace("{0}", formattedNumber);
};

// format number with separators
// -------------------------------------------------------------------------------------------
const formatNumberWithSeparators = (value: number): string => {
	const locale = DPLang.getCurrentLocaleConfig();
	const { separators } = locale.number;

	const [integerPart, decimalPart = ""] = Math.abs(value).toString().split(".");

	const groups: string[] = [];
	let remaining = integerPart;

	while (remaining.length > 0) {
		const group = remaining.slice(-separators.group);
		groups.unshift(group);
		remaining = remaining.slice(0, -separators.group);
	}

	const formattedInteger = groups.join(separators.thousand);

	const result = decimalPart ? `${formattedInteger}${separators.decimal}${decimalPart}` : formattedInteger;

	return value < 0 ? `-${result}` : result;
};
