import { debugError } from "@dp-core/error/debugError";
import { format } from "@dp-core/lang/format/_format";
import { formatDate } from "@dp-core/lang/format/formatDate";
import { formatNumber } from "@dp-core/lang/format/formatNumber";
import { en_US } from "@dp-core/lang/translations/en-US/_config";
import { sv_SE } from "@dp-core/lang/translations/sv-SE/_config";
import type { DPLangs, DPLocaleConfig, ParseRes } from "@dp-core/lang/types";

export class DPLang {
  // static privates
  // ---------------
  static configs: Record<string, DPLocaleConfig> = {};

  private static _phrases(iso: string) {
    return this.configs[iso]?.phrases;
  }

  private static getPhrase(lang: DPLangs, key: string): string | undefined {
    if (key?.[0] === ":") {
      return key?.slice(1) as string;
    }
    return this._phrases(lang)[key];
  }

  private static assertLang(lang: DPLangs) {
    const has = !!this.configs[lang].phrases;
    debugError(!has, `Lang \"${lang}\" not added`);
  }

  // static constructor
  // ------------------
  static {
    this.registerConfigs({ en_US, sv_SE });
    this.setLocale("en-US");
  }

  static getLangs(): DPLangs[] {
    const keys = Object.keys(this.configs);
    return keys as DPLangs[];
  }

  static getDefaultLang(): DPLangs {
    return this.getLangs()[0];
  }

  // locale
  // ------
  private static locale: DPLangs = "en-US";

  static getCurrentLocale(): DPLangs {
    return this.locale || "en-US";
  }

  static getCurrentLocaleConfig(): DPLocaleConfig {
    return this.configs[this.getCurrentLocale()];
  }

  static setLocale(locale: DPLangs = "en-US"): void {
    // check if locale is valid
    // ------------------------
    const langs = this.getLangs();
    const hasLang = langs.includes(locale);
    debugError(!hasLang, `Locale "${locale}" not added`);

    // set locale
    // ----------
    this.locale = locale;
  }

  static isLocale(locale: DPLangs): boolean {
    return this.locale === locale;
  }

  private static registerConfigs(configs: Record<string, DPLocaleConfig>): void {
    const langKeys = Object.keys(configs) as DPLangs[];

    langKeys.forEach((langKey) => {
      const _langKey = langKey.replace("_", "-");
      this.configs[_langKey] = configs[langKey];
    });
  }

  static registerPhrases(config: Record<string, Record<string, string>>): void {
    const langKeys = Object.keys(config) as DPLangs[];

    langKeys.forEach((langKey) => {
      const _langKey = langKey.replace("_", "-");
      const phrases = config[langKey];

      // merge phrase
      // ------------
      const curr = this.configs[_langKey]?.phrases;
      this.configs[_langKey].phrases = { ...curr, ...phrases };
    });
  }

  // parse to string
  // ---------------
  static parseToStr(phraseKey: string | undefined | null): string {
    return this.parse(phraseKey)?.toString() as string;
  }

  // parse
  // -----
  static parse(phraseKey: string | undefined | null): ParseRes {
    if (!phraseKey || typeof phraseKey !== "string") {
      return;
    }

    // check so lang is added
    // ----------------------
    const locale = this.getCurrentLocale();
    this.assertLang(locale as DPLangs);
    let res: ParseRes = this.getPhrase(locale, phraseKey);

    if (!res) {
      res = `.${phraseKey}`;
    }

    return res;
  }

  // create keys
  // -----------
  static phraseKeys<
    T extends Record<string, string>[],
    U = T[number]
  >(...phrases: T): {
      [K in keyof U]: K & keyof U;
    } {
    return Object.fromEntries(
      Object.keys(Object.assign({}, ...phrases)).map(key => [key, key])
    ) as { [K in keyof U]: K & keyof U };
  }

  // has
  // ---
  static has(phraseKey: string): boolean {
    return !!this.parse(phraseKey);
  }

  // formats
  // -------
  static format = format;
  static formatDate = formatDate;
  static formatNumber = formatNumber;
}
