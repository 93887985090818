export const copyWithoutGetters = (source: any, target = {}) => {
	const keys = Object.keys(source);

	keys.forEach((key) => {
		const descriptor = Object.getOwnPropertyDescriptor(source, key);

		if (!descriptor?.get) {
			Object.defineProperty(target, key, descriptor as PropertyDescriptor);
		}
	});

	return target;
};
