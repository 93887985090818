import { type ReactNode, type ReactElement, Children, isValidElement, cloneElement } from 'react';


export const cloneChildren = (
  children: ReactNode,
  callback?: (props: Record<string, any>, child?: ReactElement) => void
): ReactNode => {
  return (
    Children.map(children, (child) => {
      if (isValidElement(child)) {
        let newProps = { ...child.props };
        newProps = callback?.(newProps, child) || newProps;
        return cloneElement(child, newProps);
      }
      return child;
    }) || []
  );
};
