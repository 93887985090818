export const merge = (obj1: any, obj2: any) => {
	const result = { ...obj1 };
	for (const key in obj2) {
		if (obj2[key] && typeof obj2[key] === "object") {
			result[key] = merge(result[key] || {}, obj2[key]);
		} else {
			result[key] = obj2[key];
		}
	}
	return result;
};
