import type { DPData } from "@dp-core/model/base/DPData";

export const reduceProps = <T extends DPData>(data: T | T[], propsToKeep?: string[] | undefined): T | T[] => {
	if (!data) return data;

	const reducedFields = (item: T) => {
		if (!propsToKeep || propsToKeep.length === 0) return item;

		const newItem = { _id: item._id, _type: item._type } as T;

		for (const field of propsToKeep) {
			if (field in item) {
				const propKey = field as keyof T;
				newItem[propKey] = item[propKey];
			}
		}

		return newItem;
	};

	const res = Array.isArray(data) ? data.map(reducedFields) : reducedFields(data);
	return res;
};
