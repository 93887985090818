export enum DPApps {
	p2 = "p2",
	flows = "flows",
	mrm = "mrm",
	swish_out = "swish_out",
	template = "template",
	pawn_shop = "pawn_shop",
	pawn_shop_customer = "pawn_shop_customer",
	management_planner = "management_planner",
	docs = "docs",
}
