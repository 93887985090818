import { lazy } from "react";
import { DPApps } from "../apps.types";

export const getLoginComponent = (app: DPApps) => {
	switch (app) {
		case DPApps.management_planner:
			return lazy(() => import("@src/views/LoginView"));
		case DPApps.pawn_shop:
			return lazy(() => import("../common/DP2Login/DP2Login"));
		case DPApps.pawn_shop_customer:
			return lazy(() => import("@src/views/LoginView/BankId.view"));
		case DPApps.docs:
			return lazy(() => import("@src/views/LoginView"));
		default:
			throw new Error("App not found");
	}
};
