export const clonePropsAndFn = <T>(obj: T): T => {
	if (obj === null || typeof obj !== "object") return obj;

	if (Array.isArray(obj)) {
		return obj.map((item) => clonePropsAndFn(item)) as unknown as T;
	}

	if (obj instanceof Date) {
		return new Date(obj.getTime()) as unknown as T;
	}

	if (obj instanceof Map) {
		const clonedMap = new Map();
		obj.forEach((v, k) => {
			clonedMap.set(k, clonePropsAndFn(v));
		});
		return clonedMap as unknown as T;
	}

	if (obj instanceof Set) {
		const clonedSet = new Set();
		obj.forEach((v) => {
			clonedSet.add(clonePropsAndFn(v));
		});
		return clonedSet as unknown as T;
	}

	const clonedObj: Record<string | symbol, unknown> = {};
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			clonedObj[key] = clonePropsAndFn((obj as Record<string, unknown>)[key]);
		}
	}

	return clonedObj as T;
};
