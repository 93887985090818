export const setVal = (obj: any, path: string, value: unknown): void => {
	const keys = path.split(".");
	const lastKey = keys.pop();

	let current = obj;
	for (const key of keys) {
		current = current[key] = current[key] || {};
	}

	current[lastKey as string] = value;
};
