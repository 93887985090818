import { debugError } from "@dp-core/error/debugError";
import { ArrEx } from "@dp-core/utils/array/ArrEx";
import { isValidElement } from "react";

export const getType = (val: unknown): string => {
	if (val == null || typeof val === "undefined") return "empty";
	if (isValidElement(val)) return "react-element";
	if (ArrEx.isArrayLike(val)) return "array";
	if (val instanceof Map) return "map";
	if (typeof val === "function") return "function";
	if (typeof val === "string") return "string";
	if (typeof val === "symbol") return "symbol";
	if (typeof val === "boolean") return "boolean";
	if (typeof val === "number") return "number";
	if (typeof val === "object") return "object";
	debugError("unhandled value type", val);
	return "unhandled";
};
