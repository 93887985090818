
export const isComponentType = (component: any): boolean => {
  if (typeof component !== 'function') {
    return false;
  }

  if (component.displayName || /^[A-Z]/.test(component.name)) {
    return true;
  }

  if (component.prototype?.render ||
    component.prototype?.isReactComponent) {
    return true;
  }

  return false;
};
