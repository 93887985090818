import {
  TypedUseSelectorHook, useDispatch, useSelector,
} from 'react-redux'
import { AnyAction, Dispatch } from 'redux';
import { Account, AccountAttributes, User } from '../graphql/generated';
import { InitialSettingState } from './settings/reducers'
import { ParentEditorState } from './parentEditor/reducers'
import { P2ParentEditorState } from './p2ParentEditor/reducers'
import { P2QueriesState } from './p2Queries/reducers';
import { P2SearchTextState } from './p2SearchText/reducers';
import { LoggedInUser } from './redux.types';

export type RootState = {
  user: LoggedInUser,
  account: Account & { _db: string },
  parentEditor: ParentEditorState,
  p2ParentEditor: P2ParentEditorState,
  p2Queries: P2QueriesState,
  p2SearchText: P2SearchTextState,
  settings: typeof InitialSettingState,
  filters: User['filters'],
  accountAttributes: AccountAttributes,
}
export type AppDispatch = Dispatch<AnyAction>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useDPReduxAppSelector: TypedUseSelectorHook<RootState> = useSelector
