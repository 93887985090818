import { omit } from "./omit";

export const isEqual_deep = (obj1: any, obj2: any, settings?: { excludeUndefinedValues: boolean }): boolean => {
	// handle primitives and NaN
	// -------------------------
	if (Object.is(obj1, obj2)) {
		return true;
	}

	// handle non-objects and null
	// ---------------------------
	if (obj1 == null || obj2 == null || typeof obj1 !== "object" || typeof obj2 !== "object") {
		return obj1 === obj2;
	}

	let _obj1 = obj1;
	let _obj2 = obj2;

	// handle arrays
	// -------------
	if (Array.isArray(_obj1) && Array.isArray(_obj2)) {
		return _obj1.length === _obj2.length && _obj1.every((item, index) => isEqual_deep(item, _obj2[index], settings));
	}

	// compare timestamps
	// ----------------
	if (_obj1 instanceof Date && _obj2 instanceof Date) {
		return _obj1.getTime() === _obj2.getTime();
	}

	// compare regex strings
	// ---------------------
	if (_obj1 instanceof RegExp && _obj2 instanceof RegExp) {
		return _obj1.toString() === _obj2.toString();
	}

	// handle maps
	// -----------
	if (_obj1 instanceof Map && _obj2 instanceof Map) {
		if (_obj1.size !== _obj2.size) return false;

		for (const [key, value] of _obj1) {
			if (!_obj2.has(key) || !isEqual_deep(value, _obj2.get(key), settings)) {
				return false;
			}
		}
		return true;
	}

	// handle sets
	// -----------
	if (_obj1 instanceof Set && _obj2 instanceof Set) {
		if (_obj1.size !== _obj2.size) return false;

		const arr2 = Array.from(_obj2);
		return Array.from(_obj1).every((item) => arr2.some((setItem) => isEqual_deep(item, setItem, settings)));
	}

	// remove undefined values
	// -----------------------
	if (settings?.excludeUndefinedValues) {
		const removeUndefined = (obj: Record<string, unknown>) =>
			omit(obj, ...Object.keys(obj).filter((key) => obj[key] === undefined));

		_obj1 = removeUndefined(_obj1);
		_obj2 = removeUndefined(_obj2);
	}

	// compare keys
	// ------------
	const keysObj1 = Object.keys(_obj1);
	const keysObj2 = Object.keys(_obj2);

	if (keysObj1.length !== keysObj2.length) {
		return false;
	}

	// compare values
	// --------------
	return keysObj1.every((key) => {
		if (typeof _obj1[key] === "function") return true;

		return keysObj2.includes(key) && isEqual_deep(_obj1[key], _obj2[key], settings);
	});
};
