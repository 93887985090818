import { getNodeType } from "@dp-core/utils/DPReactUtil/functions/getNodeType";
import { type ReactNode, isValidElement, Children, cloneElement } from 'react';

export const crawlElementTree = (
  node: ReactNode,
  callback: (node: JSX.Element, nodeName: string, className: string, path: string) => JSX.Element | undefined | null,
  config?: {
    elementPropertyMatcher?: Record<string, string>;
    parentPath?: string;
    omitElements?: string[];
    includeTextNodes?: boolean;
  }
): ReactNode => {
  if (!isValidElement(node)) {
    return node;
  }

  const { elementPropertyMatcher, parentPath = "", omitElements = [] } = config || {};

  const k = node.props?.k;
  const nodeType = getNodeType(node);
  const cls = node.props?.cls || "";
  let path = "";

  if (omitElements.includes(nodeType) || k === undefined) {
    path = parentPath;
  } else {
    path = parentPath ? `${parentPath}.${k}` : k;
  }

  const matchedProp = elementPropertyMatcher?.[nodeType];

  let processedNode = callback(node, nodeType, cls, path);

  if (processedNode === null) {
    return null;
  }

  processedNode = processedNode || node;

  const processChildren = (children: ReactNode): ReactNode => Children.map(children, (child) => crawlElementTree(child, callback, { elementPropertyMatcher, parentPath: path, omitElements })
  );

  const childrenResult = processChildren(processedNode.props.children);
  const matchedPropValue = processedNode.props[matchedProp as string];
  const matchedPropResult = matchedPropValue
    ? crawlElementTree(matchedPropValue, callback, { elementPropertyMatcher, parentPath: path, omitElements })
    : undefined;

  if (childrenResult === processedNode.props.children &&
    (!matchedProp || matchedPropResult === processedNode.props[matchedProp])) {
    return processedNode;
  }

  const newProps = { ...processedNode.props, nodeName: nodeType, path };

  if (matchedProp && matchedPropResult !== undefined) {
    newProps[matchedProp] = matchedPropResult;
  }

  return cloneElement(processedNode, newProps, childrenResult);
};
