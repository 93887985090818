export class MapEx<K, V> extends Map<K, V> {
	static first<V>(map: Map<any, V>, filter?: (value: V) => boolean): V | undefined {
		for (const value of map.values()) {
			if (!filter || filter(value)) return value;
		}
		return undefined;
	}

	static last<V>(map: Map<any, V>, filter?: (value: V) => boolean): V | undefined {
		const values = Array.from(map.values());
		for (let i = values.length - 1; i >= 0; i--) {
			if (!filter || filter(values[i])) return values[i];
		}
		return undefined;
	}

	static isMap(obj: any): obj is Map<any, any> {
		return obj instanceof Map;
	}
}
